import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPendingData, setPendingData } from "../../Data/PendingData";
import { sendRequest } from "../../services/RequestServices";
import AuthModal from "../Auth/AuthModal";
import AirFreightForm from "./Forms/AirFreightForm";
import CourierForm from "./Forms/CourierForm";
import RailFreightForm from "./Forms/RailFreightForm";
import RelocationForm from "./Forms/RelocationForm";
import RoadTransportForm from "./Forms/RoadTransportForm";
import SeaFreightForm from "./Forms/SeaFreightForm";
import RequestModal from "../RequestModal";
import { toast, ToastContainer } from "react-toastify";

const QuoteForm = () => {
  const [formData, setFormData] = useState({});

  const [countries, setCountries] = useState([]);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [requestModalState, setRequestModalState] = useState({
    loading: false,
    error: null,
  });

  const { service } = useParams();

  useEffect(() => {
    // Fetching country data from REST Countries API
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryData = response.data.map((country) => ({
          name: country.name.common,
          flag: country.flags.png,
        }));
        setCountries(countryData);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleSubmit = async () => {
    console.log("Form Submitted: ", formData);
    setShowRequestModal(true);
    setRequestModalState({ loading: true, error: null });
    setPendingData({}, "");

    const token = localStorage.getItem("token");

    if (!token || token === "undefined") {
      console.log("No user found");
      setShowAuthModal(true);
      setPendingData(formData, service);
      setShowRequestModal(false);
      setRequestModalState({ loading: false, error: null });
      console.log(getPendingData());
      return;
    }

    try {
      await sendRequest(service, formData);

      setRequestModalState({ loading: false, error: null });
    } catch (error) {
      console.error(`Error submitting ${service} request:`, error);
      setShowRequestModal(false);

      toast.error(`Error submitting ${service} request:`, error);
    } finally {
      setPendingData({}, "");
    }
  };

  return (
    <main>
      {/* Page Title */}
      <ToastContainer />
      <section className="page-title-area sky-blue-bg pt-160 pb-100 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="page-title-wrapper text-center">
                <h4 className="styled-text theme-color mb-30">
                  Request a Quote
                </h4>

                {service === "courier" && (
                  <>
                    <h1 className="h2 capitalize">Global Courier Services</h1>
                    <p className="mt-2 para">
                      Fast, reliable delivery of parcels and documents
                      worldwide.
                    </p>
                  </>
                )}
                {service === "air-freight" && (
                  <>
                    <h1 className="h2 capitalize">Air Cargo Solutions</h1>
                    <p className="mt-2 para">
                      Efficient air freight options for time-sensitive
                      international shipments.
                    </p>
                  </>
                )}
                {service === "sea-freight" && (
                  <>
                    <h1 className="h2 capitalize">Sea Shipping Services</h1>
                    <p className="mt-2 para">
                      Cost-effective, secure sea transport for large and heavy
                      goods.
                    </p>
                  </>
                )}
                {service === "rail-freight" && (
                  <>
                    <h1 className="h2 capitalize">Rail Cargo Transport</h1>
                    <p className="mt-2 para">
                      Eco-friendly rail solutions for bulk cargo across long
                      distances.
                    </p>
                  </>
                )}
                {service === "road-transport" && (
                  <>
                    <h1 className="h2 capitalize">Road Freight Logistics</h1>
                    <p className="mt-2 para">
                      Flexible, door-to-door transport for regional and
                      international deliveries.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Request Quote Form */}
      <div className="request-quote-area pt-120 pb-120 pt-lg-80 pb-lg-80 pt-md-60 pb-md-60 pt-xs-60 pb-xs-60">
        <div className="container">
          {service === "courier" && (
            <CourierForm
              handleSubmit={handleSubmit}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {service === "air-freight" && (
            <AirFreightForm
              handleSubmit={handleSubmit}
              countries={countries}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {service === "sea-freight" && (
            <SeaFreightForm
              handleSubmit={handleSubmit}
              countries={countries}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {service === "rail-freight" && (
            <RailFreightForm
              handleSubmit={handleSubmit}
              countries={countries}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {service === "road-transport" && (
            <RoadTransportForm
              handleSubmit={handleSubmit}
              countries={countries}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {service === "relocation" && (
            <RelocationForm
              handleSubmit={handleSubmit}
              countries={countries}
              formData={formData}
              setFormData={setFormData}
            />
          )}
        </div>
      </div>

      <AuthModal
        show={showAuthModal}
        onClose={() => {
          setShowAuthModal(false);
        }}
      />

      <RequestModal
        show={showRequestModal}
        onClose={() => setShowRequestModal(false)}
        loading={requestModalState.loading}
        error={requestModalState.error}
      />
    </main>
  );
};

export default QuoteForm;
