import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const SERVICES = [
    "Courier",
    "Air Freight",
    "Sea Freight",
    "Rail Freight",
    "Road Transport",
  ];

  return (
    <footer className="footer-area footer-layout-2 pos-rel pt-130 pt-md-100 pt-xs-100 fix">
      <div className="container">
        <div className="row borders-grey pb-30">
          <div
            className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp2 animated"
            data-wow-delay=".1s"
          >
            <div className="footer__widget mb-30">
              <div className="footer-log">
                <Link href="/" className="logo">
                  <img
                    src="/assets/img/logo-main.png"
                    style={{ width: "200px" }}
                    alt=""
                  />
                </Link>
              </div>
              <p className="mb-25">
                We are your one-stop international freight comparison e-market.
              </p>
              <div className="social-links footer__social mb-25">
                <Link href="#">
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link href="#">
                  <i className="fab fa-instagram"></i>
                </Link>
                <Link href="#">
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp2 animated"
            data-wow-delay=".3s"
          >
            <div className="footer__widget mb-30 pl-40 pl-md-0 pl-xs-0">
              <h4 className="widget-title mb-30">Services</h4>
              <ul className="fot-list">
                {SERVICES.map((service) => (
                  <li key={service}>
                    <Link
                      className="dropdown-item"
                      to={`/quote/${service
                        .toLowerCase()
                        .replace(/\s+/g, "-")}`}
                    >
                      {service}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp2 animated"
            data-wow-delay=".5s"
          >
            <div className="footer__widget mb-25">
              <h4 className="widget-title mb-30">Quick Links</h4>
              <ul className="fot-list">
                <li>
                  <Link to="#">About Us</Link>
                </li>
                <li>
                  <Link to="#">Contact Us</Link>
                </li>
                <li>
                  <Link to="#">FAQ</Link>
                </li>
                <li>
                  <Link to="/login">Login</Link>
                </li>
                <li>
                  <Link to="/signup">Create Account</Link>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp2 animated"
            data-wow-delay=".7s"
          >
            <div className="footer__widget mb-30">
              <h4 className="widget-title mb-30">Newsletter</h4>
              <span className="address-details mb-25">
                Subscribe to our newsletter for new and exclusive offers.
              </span>
              <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                <input
                  type="checkbox"
                  id="acceptPolicy"
                  name="acceptPolicy"
                  // checked={formData.acceptPolicy}
                  // onChange={handleCheckChange}
                  style={{ display: "inline" }}
                />
                <label
                  htmlFor="acceptPolicy"
                  style={{ marginLeft: "5px", display: "inline" }}
                >
                  I confirm that I have read, consent and agree to ShippersWise{" "}
                  <Link href="#" style={{ textDecoration: "underline" }}>
                    Terms of service
                  </Link>{" "}
                  and{" "}
                  <Link href="#" style={{ textDecoration: "underline" }}>
                    Privacy Policy
                  </Link>
                </label>
              </div>
              {/* <div className="footer-layout-2 mt-25">
                <form className="subscribe-form mb-1" action="">
                  <input
                    className="mb-15"
                    type="text"
                    placeholder="Email address"
                  />
                  <button className="fot_sub_btn">
                    <img
                      src="/assets/img/icon/right-arrow.svg"
                      alt="Right Arrow"
                    />
                  </button>
                </form>
              </div> */}

              <div className="footer-layout-2 mt-25">
                <form className="subscribe-form mb-1" action="">
                  <div className="input-wrapper">
                    <input type="text" placeholder="Email address" />
                    <button className="footer_sub_btn">
                      <img
                        src="/assets/img/icon/right-arrow.svg"
                        alt="Right Arrow"
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right-area pt-50">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-7">
              <div className="copyright mb-45 text-start text-md-start">
                <p>
                  © <Link href="index">Shipperswise</Link> Copyright{" "}
                  {currentYear} All Right Reserved
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 text-md-end">
              <div className="footer__widget mb-25">
                <ul className="fot-list d-inline-block">
                  <li>
                    <Link to="#">Cookie Policy</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="#">Terms and Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
