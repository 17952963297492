import React from "react";
import MainSlider from "./MainSlider/MainSlider";
import Solutions from "./Solutions/Solutions";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs";
import Features from "./Features/Features";
import FAQ from "./FAQ/FAQ";
import CTA from "./CTA/CTA";
import Carousel from "./Carousel/Carousel";
import '../assets/scss/main.scss'
// Adjust path as necessary

const MainComponent = () => {
  return (
    <main>
      <MainSlider />
      <Solutions />
      <WhyChooseUs />
      <Features />
      {/*<FAQ />*/}
      <Carousel />
      <CTA />
      {/* Include other components like Footer here */}
    </main>
  );
};

export default MainComponent;
